
const userKey = 'user';

function isLoggedIn() {
    return getUser() != null;
}

function getUser() {
    return JSON.parse(sessionStorage.getItem(userKey));
}

function saveUser(user){
    sessionStorage.setItem(userKey, JSON.stringify(user));
}

function deleteUser() {
    sessionStorage.removeItem(userKey)
}

export default { isLoggedIn, saveUser, deleteUser, getUser };