let socket = null;
let reconnectInterval = 5000;
let shouldReconnect = true;
let heartbeatInterval = 30000;
let heartbeatTimeout = null;
const url = "wss://ezn663gfsa.execute-api.eu-west-1.amazonaws.com/dev";

const connect = (onMessage) => {
    shouldReconnect = true;
    socket = new WebSocket(url);
    
    const startHeartbeat = () => {
        if (heartbeatTimeout) clearTimeout(heartbeatTimeout);
        
        heartbeatTimeout = setTimeout(() => {
            if (socket && socket.readyState === WebSocket.OPEN) {
                socket.send(JSON.stringify({ action: 'heartbeat' }));
                startHeartbeat();
            }
        }, heartbeatInterval);
    };
    
    socket.onopen = () => {
        console.log("WebSocket connection opened");
        startHeartbeat();
    };
    
    socket.onmessage = (wsEvent) => {
        const { event, payload } = JSON.parse(wsEvent.data);
        if (onMessage) onMessage({event, payload});
    };
    
    socket.onclose = () => {
        console.log("WebSocket connection closed");
        if (heartbeatTimeout) clearTimeout(heartbeatTimeout);
        if (shouldReconnect) {
            setTimeout(() => {
                console.log("Reconnecting WebSocket...");
                connect(onMessage);
            }, reconnectInterval);
        }
    };
    
    socket.onerror = (error) => {
        console.error("WebSocket error:", error);
        socket.close();
    };
};

const disconnect = () => {
    shouldReconnect = false;
    if (socket) {
        socket.close();
        socket = null;
    }
};

export default {
    connect,
    disconnect
};
