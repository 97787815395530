export const SET_TARGETS = 'SET_TARGETS';
export const SET_DEVICES = 'SET_DEVICES';
export const SET_AXIES = 'SET_AXIES';
export const SET_TARGET = 'SET_TARGET';
export const SET_DEVICE = 'SET_DEVICE';
export const ADD_FRAGMENT = 'ADD_FRAGMENT';
export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';
export const ADD_AXIS_REQUEST = 'ADD_AXIS_REQUEST';
export const ADD_AXIS_SUCCESS = 'ADD_AXIS_SUCCESS';
export const ADD_AXIS_FAILURE = 'ADD_AXIS_FAILURE';
export const ADD_AXIS_RESET = 'DELETE_AXIS_RESET';
export const DELETE_AXIS_REQUEST = 'DELETE_AXIS_REQUEST';
export const DELETE_AXIS_SUCCESS = 'DELETE_AXIS_SUCCESS';
export const DELETE_AXIS_FAILURE = 'DELETE_AXIS_FAILURE';
export const DELETE_AXIS_RESET = 'DELETE_AXIS_RESET';
export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAILURE = 'UPDATE_PROJECT_FAILURE';
export const RESET_PROJECT_ERROR = 'RESET_PROJECT_ERROR';
export const ADD_CONTROL_POINT_REQUEST = 'ADD_CONTROL_POINT_REQUEST';
export const ADD_CONTROL_POINT_SUCCESS = 'ADD_CONTROL_POINT_SUCCESS';
export const ADD_CONTROL_POINT_FAILURE = 'ADD_CONTROL_POINT_FAILURE';
export const RESET_ADD_CONTROL_POINT_ERROR = 'RESET_ADD_CONTROL_POINT_ERROR';
export const DELETE_CONTROL_POINT_REQUEST = 'DELETE_CONTROL_POINT_REQUEST';
export const DELETE_CONTROL_POINT_SUCCESS = 'DELETE_CONTROL_POINT_SUCCESS';
export const DELETE_CONTROL_POINT_FAILURE = 'DELETE_CONTROL_POINT_FAILURE';
export const DELETE_CONTROL_POINT_RESET = 'DELETE_CONTROL_POINT_RESET';
export const CREATE_GROUP_RESET = 'CREATE_GROUP_RESET';
export const CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILURE = 'CREATE_GROUP_FAILURE';
export const DELETE_TARGET_GROUP_REQUEST = 'DELETE_TARGET_GROUP_REQUEST';
export const DELETE_TARGET_GROUP_SUCCESS = 'DELETE_TARGET_GROUP_SUCCESS';
export const DELETE_TARGET_GROUP_FAILURE = 'DELETE_TARGET_GROUP_FAILURE';
export const DELETE_TARGET_GROUP_RESET = 'DELETE_TARGET_GROUP_RESET';
export const ADD_OR_EDIT_TARGET_FAILURE = 'ADD_OR_EDIT_TARGET_FAILURE';
export const ADD_OR_EDIT_TARGET_REQUEST = 'ADD_OR_EDIT_TARGET_REQUEST';
export const ADD_OR_EDIT_TARGET_SUCCESS = 'ADD_OR_EDIT_TARGET_SUCCESS';
export const ADD_OR_EDIT_TARGET_RESET = 'ADD_OR_EDIT_TARGET_RESET';
export const DELETE_TARGET_REQUEST = 'DELETE_TARGET_REQUEST';
export const DELETE_TARGET_SUCCESS = 'DELETE_TARGET_SUCCESS';
export const DELETE_TARGET_FAILURE = 'DELETE_TARGET_FAILURE';
export const DELETE_TARGET_RESET = 'DELETE_TARGET_RESET';