import axios from "axios";

axios.defaults.baseURL = 'https://60rsmwwqqg.execute-api.eu-west-1.amazonaws.com/dev/api';

const getProjects = async () => {
    const {data} = await axios.get('/projects');
    return data;
}

const getProject = async (projectId) => {
    const {data} = await axios.get(`/projects/${projectId}`);
    return data;
}

const getTargets = async (projectId) => {
    const {data} = await axios.get(`/projects/${projectId}/targets`);
    return JSON.parse(JSON.stringify(data));
}

const getTargetGroups = async (projectId) => {
    const {data} = await axios.get(`/projects/${projectId}/targetGroups`);
    return data;
}

const getControlPoints = async (projectId) => {
    const {data} = await axios.get(`/projects/${projectId}/controlPoints`);
    return data;
}

const getDevices = async (projectId) => {
    const {data} = await axios.get(`/projects/${projectId}/devices`);
    return data;
}

const getAxies = async (projectId) => {
    const {data} = await axios.get(`/projects/${projectId}/axies`);
    return data;
}

const getTargetHistory = async (id) => {
    const {data} = await axios.get(`/targets/${id}/history`);
    return data;
}

const getTargetHistory2 = async (id, res, from, to) => {
    const {data} = await axios.get(`/targets/${id}/history2?resolution=${res}&from=${from}&to=${to}`);
    return data;
}

const getLastHistory = async (id) => {
    const {data} = await axios.get(`/targets/${id}/last`);
    return data;
    
}

const addAxis = async (projectId, name,file) => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('file', file);
    
    const {data} = await axios.post(`/projects/${projectId}/axies`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return data;
}

const addControlPoint = async (projectId, point) => {
    const {data} = await axios.post(`/projects/${projectId}/controlPoints`, point);
    return data;
}

const getExports = async (projectId) => {
    const {data} = await axios.get(`/exports`);
    //const {data} = await axios.get(`/projects/${projectId}/exports`);
    return data;
}

const deleteExport = async (id) => {
    const {data} = await axios.delete(`/exports/${id}`);
    return data;
}

const createExport = async (exportReq) => {
    const {data} = await axios.post(`/exports`, exportReq);
    return data;
}

const createGroup = async (projectId, group) => {
    const {data} = await axios.post(`/projects/${projectId}/targetGroups`, group);
    return data;
}

const login = async (user, password) => {
    const {data} = await axios.post(`/login`,{user, password});
    return data;
}

const deleteAxis = async (id) => {
    const {data} = await axios.delete(`/axies/${id}`);
    return data;
}

const deleteControlPoint = async (id) => {
    const {data} = await axios.delete(`/controlPoints/${id}`);
    return data;
}

const updateProject = async (projectId, project) => {
    const {data} = await axios.put(`/projects/${projectId}`, project);
    return data;
}

const addTarget = async (projectId, target) => {
    const {data} = await axios.post(`/projects/${projectId}/targets`, target);
    return data;
}

const editTarget = async (targetId, target) => {
    const {data} = await axios.put(`/targets/${targetId}`, target);
    return data;
}
    
const deleteTargetGroup = async (groupId) => {
    const {data} = await axios.delete(`/targetGroups/${groupId}`);
    return data;
}

const deleteTarget = async (id) => {
    const {data} = await axios.delete(`/targets/${id}`);
    return data;
}

export default {
    login,
    addTarget,
    getProjects,
    getTargets,
    getDevices,
    getAxies,
    getTargetHistory,
    getTargetHistory2,
    getLastHistory,
    addAxis,
    getExports,
    deleteExport,
    createExport,
    deleteAxis,
    getTargetGroups,
    getControlPoints,
    addControlPoint,
    getProject,
    updateProject,
    deleteControlPoint,
    createGroup,
    deleteTargetGroup,
    deleteTarget,
    editTarget,
}