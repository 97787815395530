import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import {thunk} from 'redux-thunk';
import auth from "./helpers/auth";

import {
    SET_TARGETS,
    SET_DEVICES,
    SET_AXIES,
    SET_TARGET,
    SET_DEVICE,
    ADD_FRAGMENT,
    FETCH_DATA_REQUEST,
    FETCH_DATA_SUCCESS,
    FETCH_DATA_FAILURE,
    ADD_AXIS_REQUEST,
    ADD_AXIS_SUCCESS,
    ADD_AXIS_FAILURE,
    ADD_AXIS_RESET,
    DELETE_AXIS_REQUEST,
    DELETE_AXIS_SUCCESS,
    DELETE_AXIS_FAILURE,
    DELETE_AXIS_RESET,
    UPDATE_PROJECT_REQUEST,
    UPDATE_PROJECT_SUCCESS,
    UPDATE_PROJECT_FAILURE,
    RESET_PROJECT_ERROR,
    ADD_CONTROL_POINT_REQUEST,
    ADD_CONTROL_POINT_SUCCESS,
    ADD_CONTROL_POINT_FAILURE,
    RESET_ADD_CONTROL_POINT_ERROR,
    DELETE_CONTROL_POINT_REQUEST,
    DELETE_CONTROL_POINT_SUCCESS,
    DELETE_CONTROL_POINT_FAILURE,
    DELETE_CONTROL_POINT_RESET,
    CREATE_GROUP_FAILURE,
    CREATE_GROUP_REQUEST,
    CREATE_GROUP_SUCCESS,
    CREATE_GROUP_RESET,
    ADD_OR_EDIT_TARGET_FAILURE,
    ADD_OR_EDIT_TARGET_REQUEST,
    ADD_OR_EDIT_TARGET_SUCCESS,
    ADD_OR_EDIT_TARGET_RESET,
    DELETE_TARGET_GROUP_REQUEST,
    DELETE_TARGET_GROUP_SUCCESS,
    DELETE_TARGET_GROUP_FAILURE,
    DELETE_TARGET_GROUP_RESET,
    DELETE_TARGET_REQUEST,
    DELETE_TARGET_SUCCESS,
    DELETE_TARGET_FAILURE,
    DELETE_TARGET_RESET,
} from './actions/actionTypes';


const devices = (state = [], {type, payload}) => {
    switch (type) {
        case SET_DEVICES:
            return payload;
        case SET_DEVICE:
            return state.map(device => device.id === payload.id ? payload : device);
        default:
            return state
    }
}

const targetsFragments = (state = {}, {type, payload}) => {
    switch (type) {
        case ADD_FRAGMENT:
            const {targetId, timestamp, totalFragments } = payload;
            const targetFragments = state[targetId] || {};
            if(!targetFragments[timestamp]) {
                targetFragments[timestamp] = [];
            }
            targetFragments[timestamp].push(payload);
            if(targetFragments[timestamp].length === totalFragments) {
                console.log("FRAGMENT COMPLETE", totalFragments);
                const newState = {};
                Object.keys(targetFragments).map(key => {
                   if(key < timestamp) {
                       delete targetFragments[key];
                       console.log("FRAGMENT COMPLETE2", key);
                   }
                });
            }
            console.log("FRAGMENT", targetFragments);
            return { ...state, [targetId]:targetFragments };
        default:
            return state
    }
}

const targets = (state = [], {type, payload}) => {
    switch (type) {
        case SET_TARGETS:
            return payload;
        case SET_TARGET:
            console.log("SET_TARGETS1", payload);
            return state.map(target => {
                const {targetId} = target;
                for(const t of payload) {
                    if(targetId === t.targetId)
                        return t;
                }
                return target;
            });
           

        default:
            return state
    }
}

const targetGroups = (state = [
    
], {type, payload}) => {
    switch (type) {
        case "SET_TARGET_GROUPS":
            return payload;
        default:
            return state;
    }
}

const controlPoints = (state = [
], {type, payload}) => {
    switch (type) {
        case "SET_CONTROL_POINTS":
            return payload;
        case ADD_CONTROL_POINT_SUCCESS:
            return [...state, payload];    
        default:
            return state;
    }
}

const axies = (state= [], {type, payload}) => {
    switch (type) {
        case SET_AXIES:
            return payload;
        default:
            return state;
    }
}

const axiesById = (state= [], {type, payload}) => {
    switch (type) {
        case SET_AXIES:
            return payload.reduce((acc, axis) => {
                acc[axis.axisId] = axis
                return acc;
            }, {});
        default:
            return state;
    }
}

/*const project = (state = {
    start: 601348.677,
    end: 600430.037,
    reference: "44a0a8fb-2eb2-443a-ba8b-ec104fa6f91c",
    weather: "4b65417b-5908-454d-87b0-bbe2d77518c3",
    tolerance: {
        warning: 0.01,
        error: 0.015
    }
}) => state;*/


const enhancers = [];
if(window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}
const middleware = [thunk];

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

let savedUser = auth.getUser();
const userInitialState = savedUser ? {loading:false, logged: true, user:savedUser} : {loading:false, logged: false};

function user(state = userInitialState, action) {
    switch(action.type) {
        case "LOGIN_REQUEST":
            return {loading:true, logged: false};
        case "LOGIN_FAIL":
            return {loading:false, logged: false, error:action.payload};
        case "LOGIN_SUCCESS":
            return {loading:false, logged: true, user: action.payload};
        case "LOGOUT":
            return {loading:false, logged: false};
        default:
            return state;
    }
}

const dataReducer = (state = {
    loading: false,
    error: null
}, action) => {
    switch (action.type) {
        case FETCH_DATA_REQUEST:
            return {...state, loading: true, error: null};
        case FETCH_DATA_SUCCESS:
            return {...state, loading: false};
        case FETCH_DATA_FAILURE:
            return {...state, loading: false, error: action.error};
        default:
            return state;
    }
}

const addAxisState = (state = { loading: false, error: null, success: false }, action) => {
    switch (action.type) {
        case ADD_AXIS_REQUEST:
            return { loading: true, success: false, error: null };
        case ADD_AXIS_RESET:
            return { loading: false, success: false, error: null };
        case ADD_AXIS_SUCCESS:
            return { loading: false, success: true, error: null };
        case ADD_AXIS_FAILURE:
            return { loading: false, success: false, error: action.error };
        default:
            return state;
    }
};

const deleteAxisState = (state = { loading: false, success: false, error: null }, action) => {
    switch (action.type) {
        case DELETE_AXIS_REQUEST:
            return { loading: true, success: false, error: null };
        case DELETE_AXIS_RESET:
            return { loading: false, success: false, error: null };
        case DELETE_AXIS_SUCCESS:
            return { loading: false, success: true, error: null };
        case DELETE_AXIS_FAILURE:
            return { loading: false, success: false, error: action.error };
        default:
            return state;
    }
};

const deleteControlPointState = (state = { loading: false, success: false, error: null }, action) => {
    switch (action.type) {
        case DELETE_CONTROL_POINT_REQUEST:
            return { loading: true, success: false, error: null };
        case DELETE_CONTROL_POINT_RESET:
            return { loading: false, success: false, error: null };
        case DELETE_CONTROL_POINT_SUCCESS:
            return { loading: false, success: true, error: null };
        case DELETE_CONTROL_POINT_FAILURE:
            return { loading: false, success: false, error: action.error };
        default:
            return state;
    }
};

const addControlPointInitialState = {
    loading: false,
    success: false,
    error: null
};

const addControlPointState = (state = addControlPointInitialState, action) => {
    switch (action.type) {
        case ADD_CONTROL_POINT_REQUEST:
            return { ...state, loading: true, success: false, error: null };
        case RESET_ADD_CONTROL_POINT_ERROR:
            return { ...state, loading: false, success: false, error: null };
        case ADD_CONTROL_POINT_SUCCESS:
            return { ...state, loading: false, success: true, error: null };
        case ADD_CONTROL_POINT_FAILURE:
            return { ...state, loading: false, success: false, error: action.payload };
        case RESET_ADD_CONTROL_POINT_ERROR:
            return { ...state, error: null };
        default:
            return state;
    }
};

/*
const initialProjectState = {
    data: {
        start: '',
        end: '',
        tolerance: {
            warning: '',
            error: ''
        }
    },
    loading: false,
    error: null
};*/
const initialProjectState = savedUser ? savedUser.project : null;

const updateProjectInitialState = {
    loading: false,
    success: false,
    error: null
};

const updateProjectState = (state = updateProjectInitialState, action) => {
    switch (action.type) {
        case UPDATE_PROJECT_REQUEST:
            return { ...state, loading: true, success: false, error: null };
        case UPDATE_PROJECT_SUCCESS:
            return { ...state, loading: false, success: true, error: null };
        case UPDATE_PROJECT_FAILURE:
            return { ...state, loading: false, success: false, error: action.payload };
        case RESET_PROJECT_ERROR:
            return { ...state, error: null, success: false };
        default:
            return state;
    }
};

const createGroupInitialState = {
    loading: false,
    success: false,
    error: null
};

const createGroupState = (state = createGroupInitialState, action) => {
    switch (action.type) {
        case CREATE_GROUP_REQUEST:
            return { ...state, loading: true, success: false, error: null };
        case CREATE_GROUP_SUCCESS:
            return { ...state, loading: false, success: true, error: null };
        case CREATE_GROUP_FAILURE:
            return { ...state, loading: false, success: false, error: action.payload };
        case CREATE_GROUP_RESET:
            return { ...state, success: false, error: null };
        default:
            return state;
    }
};

const addOrEditTargetInitialState = {
    loading: false,
    success: false,
    error: null
};

const addOrEditTargetState = (state = addOrEditTargetInitialState, action) => {
    switch (action.type) {
        case ADD_OR_EDIT_TARGET_REQUEST:
            return { ...state, loading: true, success: false, error: null };
        case ADD_OR_EDIT_TARGET_SUCCESS:
            return { ...state, loading: false,success: true, error: null };
        case ADD_OR_EDIT_TARGET_FAILURE:
            return { ...state, loading: false,success: false, error: action.payload };
        case ADD_OR_EDIT_TARGET_RESET:
            return { ...state, error: null, success: false };
        default:
            return state;
    }
}

const currentProjectInitialState = savedUser ? savedUser.project : null;

const currentProject = (state = currentProjectInitialState, action) => {
    switch (action.type) {
        case "LOGIN_SUCCESS":
            return action.payload.project;
        default:
            return state;
    }
};


const project = (state = initialProjectState, action) => {
    switch (action.type) {
        case UPDATE_PROJECT_SUCCESS:
            return action.payload;
        default:
            return state;
    }
};

const deleteTargetGroupState = (state = { loading: false, success: false, error: null }, action) => {
    switch (action.type) {
        case DELETE_TARGET_GROUP_REQUEST:
            return { loading: true, success: false, error: null };
        case DELETE_TARGET_GROUP_RESET:
            return { loading: false, success: false, error: null };
        case DELETE_TARGET_GROUP_SUCCESS:
            return { loading: false, success: true, error: null };
        case DELETE_TARGET_GROUP_FAILURE:
            return { loading: false, success: false, error: action.payload };
        default:
            return state;
    }
};


const deleteTargetState = (state = { loading: false, success: false, error: null }, action) => {
    switch (action.type) {
        case DELETE_TARGET_REQUEST:
            return { loading: true, success: false, error: null };
        case DELETE_TARGET_RESET:
            return { loading: false, success: false, error: null };
        case DELETE_TARGET_SUCCESS:
            return { loading: false, success: true, error: null };
        case DELETE_TARGET_FAILURE:
            return { loading: false, success: false, error: action.payload };
        default:
            return state;
    }
};

const store = createStore(combineReducers({
    targets,
    devices,
    axies,
    axiesById,
    targetGroups,
    controlPoints,
    currentProject,
    project,
    user,
    targetsFragments,
    data: dataReducer,
    addAxisState,
    deleteAxisState,
    addControlPointState,
    updateProjectState,
    deleteControlPointState,
    createGroupState,
    addOrEditTargetState,
    deleteTargetGroupState,
    deleteTargetState,
}), composedEnhancers);
export default store;

