import Backend from '../services/backend';
import Websocket from '../services/websocket';

import {
    SET_TARGETS,
    SET_DEVICES,
    SET_AXIES,
    SET_TARGET,
    SET_DEVICE,
    ADD_FRAGMENT,
    FETCH_DATA_REQUEST,
    FETCH_DATA_SUCCESS,
    FETCH_DATA_FAILURE,
    ADD_AXIS_REQUEST,
    ADD_AXIS_SUCCESS,
    ADD_AXIS_FAILURE,
    ADD_AXIS_RESET,
    DELETE_AXIS_REQUEST,
    DELETE_AXIS_SUCCESS,
    DELETE_AXIS_FAILURE,
    DELETE_AXIS_RESET,
    DELETE_CONTROL_POINT_REQUEST,
    DELETE_CONTROL_POINT_SUCCESS,
    DELETE_CONTROL_POINT_FAILURE,
    DELETE_CONTROL_POINT_RESET,
    UPDATE_PROJECT_REQUEST,
    UPDATE_PROJECT_SUCCESS,
    UPDATE_PROJECT_FAILURE,
    RESET_PROJECT_ERROR,
    ADD_CONTROL_POINT_REQUEST,
    ADD_CONTROL_POINT_SUCCESS,
    ADD_CONTROL_POINT_FAILURE,
    RESET_ADD_CONTROL_POINT_ERROR,
    CREATE_GROUP_RESET,
    CREATE_GROUP_REQUEST,
    CREATE_GROUP_SUCCESS,
    CREATE_GROUP_FAILURE,
    DELETE_TARGET_GROUP_FAILURE,
    DELETE_TARGET_GROUP_REQUEST,
    DELETE_TARGET_GROUP_RESET,
    DELETE_TARGET_GROUP_SUCCESS,
    ADD_OR_EDIT_TARGET_FAILURE,
    ADD_OR_EDIT_TARGET_REQUEST,
    ADD_OR_EDIT_TARGET_SUCCESS,
    ADD_OR_EDIT_TARGET_RESET,
    DELETE_TARGET_FAILURE,
    DELETE_TARGET_REQUEST,
    DELETE_TARGET_RESET,
    DELETE_TARGET_SUCCESS,
} from './actionTypes';

export const fetchData = () => {
    return async (dispatch, getState) => {
        dispatch({ type: FETCH_DATA_REQUEST });
        try {
            const projectId = getState().currentProject;
            //const projects = await Backend.getProjects();
            console.log("PROJECT", projectId);
            const project = await Backend.getProject(projectId);
            const targets = await Backend.getTargets(projectId);
            const devices = await Backend.getDevices(projectId);
            const axies = await Backend.getAxies(projectId);
            const targetGroups = await Backend.getTargetGroups(projectId);
            const controlPoints = await Backend.getControlPoints(projectId);
            //dispatch({ type: "SET_PROJECTS", payload: projects});
            console.log("SET_TARGETS", targets);
            dispatch({ type: UPDATE_PROJECT_SUCCESS, payload: project });
            dispatch({ type: SET_TARGETS, payload: targets });
            dispatch({ type: SET_DEVICES, payload: devices });
            dispatch({ type: SET_AXIES, payload: axies });
            dispatch({ type: "SET_TARGET_GROUPS", payload: targetGroups });
            dispatch({ type: "SET_CONTROL_POINTS", payload: controlPoints });
            dispatch({ type: FETCH_DATA_SUCCESS });
        } catch (error) {
            dispatch({ type: FETCH_DATA_FAILURE, error });
            throw error;
        }
    };
};

export const updateTarget = (target) => ({ type: SET_TARGET, payload: target });
export const updateDevice = (device) => ({ type: SET_DEVICE, payload: device });
export const addTargetFragment = (fragment) => ({ type: ADD_FRAGMENT, payload: fragment });

export const wsConnect = () => (dispatch) => {
    Websocket.connect(
        (message) => {
            handleWebSocketMessage(message, dispatch);
        },
    );
};

export const wsDisconnect = () => (dispatch) => {
    Websocket.disconnect();
};

const handleWebSocketMessage = (message, dispatch) => {
    const { event, payload } = message;
    switch (event) {
        case 'ON_DEVICE_CHANGE':
            dispatch(updateDevice(payload));
            break;
        case 'ON_TARGETS_CHANGE':
            dispatch(updateTarget(payload));
            break;
        case 'ON_TARGET_CHANGE':
            dispatch(addTargetFragment(payload));
            break;
        default:
            break;
    }
};

export const fetchAxies = () => {
    return async (dispatch, getState) => {
        try {
            const projectId = getState().currentProject;
            const axies = await Backend.getAxies(projectId);
            dispatch({ type: SET_AXIES, payload: axies });
        } catch (error) {
            console.error(error);
        }
    };
};

export const fetchControlPoints = () => {
    return async (dispatch, getState) => {
        try {
            const projectId = getState().currentProject;
            const controlPoints = await Backend.getControlPoints(projectId);
            dispatch({ type: "SET_CONTROL_POINTS", payload: controlPoints });
        } catch (error) {
            console.error(error);
        }
    };
};

export const fetchGroups = () => {
    return async (dispatch, getState) => {
        try {
            const projectId = getState().currentProject;
            const targetGroups = await Backend.getTargetGroups(projectId);
            dispatch({ type: "SET_TARGET_GROUPS", payload: targetGroups });
        } catch (error) {
            console.error(error);
        }
    };
}

export const fetchTargets = () => {
    return async (dispatch, getState) => {
        try {
            const projectId = getState().currentProject;
            const targets = await Backend.getTargets(projectId);
            dispatch({ type: SET_TARGETS, payload: targets });
        } catch (error) {
            console.error(error);
        }
    };
}


export const addAxis = (name, file) => {
    return async (dispatch, getState) => {
        dispatch({ type: ADD_AXIS_REQUEST });
        try {
            const projectId = getState().currentProject;
            await Backend.addAxis(projectId ,name, file);
            dispatch({ type: ADD_AXIS_SUCCESS });
            dispatch(fetchAxies());
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message || 'Error al añadir el eje';
            dispatch({ type: ADD_AXIS_FAILURE, error: errorMessage });
        }
    };
};

export const deleteAxis = (axisId) => {
    return async (dispatch) => {
        dispatch({ type: DELETE_AXIS_REQUEST });
        try {
            await Backend.deleteAxis(axisId);
            dispatch({ type: DELETE_AXIS_SUCCESS });
            dispatch(fetchAxies());
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message || 'Error al eliminar el eje';
            dispatch({ type: DELETE_AXIS_FAILURE, error: errorMessage });
        }
    };
};

export const resetAddAxisError = () => ({ type: ADD_AXIS_RESET });
export const resetDeleteAxisError = () => ({ type: DELETE_AXIS_RESET });



export const updateProjectRequest = () => ({
    type: UPDATE_PROJECT_REQUEST,
});

export const updateProjectSuccess = (project) => ({
    type: UPDATE_PROJECT_SUCCESS,
    payload: project,
});

export const updateProjectFailure = (error) => ({
    type: UPDATE_PROJECT_FAILURE,
    payload: error,
});

export const resetProjectError = () => ({
    type: RESET_PROJECT_ERROR,
});

export const updateProject = (project) => {
    return async (dispatch, getState) => {
        dispatch(updateProjectRequest());
        try {
            console.log("PROJECT", project);
            const projectId= getState().currentProject;
            await Backend.updateProject(projectId, project);
        
            dispatch(updateProjectSuccess(project));
        } catch (error) {
            console.log("ERROR", error);
            const errorMessage = error.response?.data?.message || error.message || 'Error al actualizar el proyecto';
            dispatch(updateProjectFailure(errorMessage));
        }
    };
};

export const deleteControlPoint = (pointId) => {
    return async (dispatch) => {
        dispatch({ type: DELETE_CONTROL_POINT_REQUEST });
        try {
            await Backend.deleteControlPoint(pointId);
            dispatch({ type: DELETE_CONTROL_POINT_SUCCESS });
            dispatch(fetchControlPoints());
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message || 'Error al eliminar el eje';
            dispatch({ type: DELETE_CONTROL_POINT_FAILURE, error: errorMessage });
        }
    };
};

export const resetDeleteControlPointError = () => ({ type: DELETE_CONTROL_POINT_RESET });
export const resetAddControlPointError = () => ({
    type: RESET_ADD_CONTROL_POINT_ERROR,
});



export const resetCreateGroupError = () => ({ type: CREATE_GROUP_RESET });
export const createGroup = (group) => {
    return async (dispatch, getState) => {
        dispatch({ type: CREATE_GROUP_REQUEST });
        try {
            const projectId = getState().currentProject;
            const targetGroups = getState().targetGroups;
            await Backend.createGroup(projectId, {...group, order: targetGroups.length}); 
            dispatch({ type: CREATE_GROUP_SUCCESS });
            dispatch(fetchGroups());
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message || 'Error al crear el grupo';
            dispatch({ type: CREATE_GROUP_FAILURE, payload: errorMessage });
        }
    };
}

export const resetDeleteTargetGroupError = () => ({ type: DELETE_TARGET_GROUP_RESET });
export const deleteTargetGroup = (groupId) => {
    return async (dispatch) => {
        dispatch({ type: DELETE_TARGET_GROUP_REQUEST });
        try {
            await Backend.deleteTargetGroup(groupId);
            dispatch({ type: DELETE_TARGET_GROUP_SUCCESS });
            dispatch(fetchGroups());
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message || 'Error al eliminar el grupo';
            dispatch({ type: DELETE_TARGET_GROUP_FAILURE, payload: errorMessage });
        }
    };
}

export const resetDeleteTargetError = () => ({ type: DELETE_TARGET_RESET });
export const deleteTarget = (targetId) => {
    return async (dispatch) => {
        dispatch({ type: DELETE_TARGET_REQUEST });
        try {
            await Backend.deleteTarget(targetId);
            dispatch({ type: DELETE_TARGET_SUCCESS });
            dispatch(fetchTargets());
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message || 'Error al eliminar el grupo';
            dispatch({ type: DELETE_TARGET_FAILURE, payload: errorMessage });
        }
    };
}

export const addControlPoint = (name, x, y, z) => {
    return async (dispatch, getState) => {
        dispatch({ type: ADD_CONTROL_POINT_REQUEST });
        try {
            
            const projectId = getState().currentProject;
            const response = await Backend.addControlPoint(projectId, { 
                name,
                x: Number(x), 
                y: Number(y),
                z: Number(z),
                });

            dispatch({
                type: ADD_CONTROL_POINT_SUCCESS,
                payload: response,
            });
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message || 'Error al añadir el punto de control';
            dispatch({
                type: ADD_CONTROL_POINT_FAILURE,
                payload: errorMessage,
            });
        }
    };
};


export const resetAddOrEditTargetError = () => ({ type: ADD_OR_EDIT_TARGET_RESET });
export const addTarget = (target) => {
    return async (dispatch, getState) => {
        dispatch({ type: ADD_OR_EDIT_TARGET_REQUEST });
        try {
            const projectId = getState().currentProject;
            await Backend.addTarget(projectId, target);
            dispatch({ type: ADD_OR_EDIT_TARGET_SUCCESS });
            dispatch(fetchTargets());
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message || 'Error al añadir el punto de control';
            dispatch({ type: ADD_OR_EDIT_TARGET_FAILURE, payload:errorMessage });
        }
    };
}

export const editTarget = (targetId, target) => {
    return async (dispatch, getState) => {
        dispatch({ type: ADD_OR_EDIT_TARGET_REQUEST });
        try {
            await Backend.editTarget(targetId, target);
            dispatch({ type: ADD_OR_EDIT_TARGET_SUCCESS });
            dispatch(fetchTargets());
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message || 'Error al añadir el punto de control';
            dispatch({ type: ADD_OR_EDIT_TARGET_FAILURE, payload:errorMessage });
        }
    };
}
