import React, { useEffect, Suspense } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchData, updateTarget, updateDevice, addTargetFragment, wsConnect, wsDisconnect } from './actions';

const loader = (
    <div className="pt-3 text-center">
        Loading...
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const Login = React.lazy(() => import('./pages/Login'));

function App({ fetchData, loading, error , logged, wsConnect, wsDisconnect}) {
    useEffect(() => {
        if(logged) {
            fetchData()
                .then(() => wsConnect())
                .catch(() => {});
        } else {
            wsDisconnect();
        }
    }, [fetchData, logged]);
    
    /*useEffect(() => {
        if (loading || !logged) return;
            wsConnect();
    }, [loading, logged]);*/
    
    return (
        <HashRouter>
            {loading && <div className="pt-3 text-center">Loading data...</div>}
            {!loading && (
                <Suspense fallback={loader}>
                    <Routes>
                        <Route exact path="/login" name="Login Page" element={<Login />} />
                        <Route path="*" name="Home" element={<DefaultLayout />} />
                    </Routes>
                </Suspense>
            )}
            {!loading && error && <div className="pt-3 text-center">Error loading data: {error.message}</div>}
        </HashRouter>
    );
}

const mapStateToProps = (state) => ({
    loading: state.data.loading,
    error: state.data.error,
    logged: state.user.logged,
});

const mapDispatchToProps = {
    fetchData,
    wsConnect,
    wsDisconnect,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
