import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store'
import auth from './helpers/auth';

let currentValue;
store.subscribe( () => {
    let previousValue = currentValue;
    currentValue = store.getState().user;
    if(currentValue !== previousValue) {
        if(currentValue.user) {
            auth.saveUser(currentValue.user);
        } else {
            auth.deleteUser();
        }
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
